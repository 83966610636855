import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Theme } from "theme";

import { AuthContainer } from "../components/AuthContainer";
import { Separator, LoadingButton } from "components";

import { TickIcon } from "./components/TickIcon";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 0 15px 68px 15px;
`;

const Heading = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
`;

const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Message = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 500;
  font-size: 17.5px;
  line-height: 22px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
  margin-left: 12px;
  flex: 1;
`;

export const EmailSent = () => {
  const themeMode = useSelector((state) => state.authReducer.theme);
  const {userDetail} = useSelector((state) => state.authReducer);
  const { push } = useRouter();
  const lang = userDetail?.user?.language? userDetail?.user?.language: "en";

  const email = localStorage.getItem("email");

  return (
    <AuthContainer>
      <Container>
        <Heading themeMode={themeMode}>
          <FormattedMessage id="emailSent.page.heading" />
        </Heading>
        <Separator height="35px" />
        <MessageContainer>
          <TickIcon />
          <Message themeMode={themeMode}>
            <FormattedMessage id="emailSent.message" values={{ email }} />
          </Message>
        </MessageContainer>
        <Separator height="40px" />
        <LoadingButton
          full
          size="lg"
          onClick={() => push(`/${lang}/${RoutePaths.Login}`)}
          data-testid='emailSent-login'
        >
          <FormattedMessage id="emailSent.btn.text" />
        </LoadingButton>
      </Container>
    </AuthContainer>
  );
};
