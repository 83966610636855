import { useState, ChangeEvent, Dispatch, SetStateAction, FormEvent, useEffect } from "react";
import { Modal, Spinner } from "components";
import classNames from "classnames";
import styles from './docUpload.module.scss';
import { useSelector } from "redux/hooks";
import { validateFileType } from "utils/functions";
import useRouter from "hooks/useRouter";

import { FormattedMessage, useIntl } from "react-intl";
import validUrl from "valid-url";
import {
    IChat,
    setChatModel,
    IChatModel,
} from "redux/actions";
import { IFileErrorState } from "pages/ChatPage/pages/newChat/NewChat";

interface IProp {
    setISUploadURL?: Dispatch<SetStateAction<boolean>>;
    setChatHistory?: Dispatch<SetStateAction<any>>;
    chatHistoryPagination?: IChat[];
    setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
    setUploadUrl?: Dispatch<SetStateAction<boolean>>;
    message?: string;
    resetMessage?: () => void;
    url?: string;
    setURL?: Dispatch<SetStateAction<string>>;
    onSendMessage?: (question: string, chatModels?: IChatModel, regenerate?: boolean, images?: string[], filePath?: string[]) => void;
    setMessageId?: Dispatch<SetStateAction<string>>;
    changeModel?: boolean;
    setChangeModel?: Dispatch<SetStateAction<boolean>>;
}

export const UploadURL = ({ setISUploadURL, url,
    setURL,
    // setChatHistory, chatHistoryPagination, 
    setErrorModal, setUploadUrl, message,
    resetMessage,
    onSendMessage,
    setMessageId,
    changeModel,
    setChangeModel,
}: IProp) => {

    const { pathname } = useRouter();
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { theme, gptModel, userDetail } = useSelector((state) => state.authReducer);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    // const { triggerNotification } = useAppNotification();
    const { formatMessage } = useIntl();
    const currentPlan = useSelector(
        (state) => state.planSubscriptionReducer.activePlan
    );

    const [allowDoc, setAllowDoc] = useState<boolean>(false);
    const [imgallow, setImgallow] = useState<boolean>(false);

    const chatId = pathname.split("/")[3];
    const chatHistory = pathname.includes('chat/new');

    const getConditionalAllowedFileTypes = () => {
        const baseFileTypes = [
            "pdf",
            "txt",
            "ppt",
            "pptx",
            // "doc",
            "docx",
            "csv",
            "xls",
            "xlsx",
            "eml",
            "srt",
            "png",
            "jpeg",
            "webp",
            "gif",
            "jpg",
        ];
        return gptModel?.name.includes("Claude") ? baseFileTypes : [...baseFileTypes, "jpg"];
    };

    const [isURLLoading, setIsURLLoading] = useState<boolean>(false);

    const getFileURL = (event: ChangeEvent<HTMLInputElement>) => {
        setURL!(event.target.value);
        const urlExtension = event.target.value?.split('.').pop()?.toLowerCase() || '';
        const imgURL = ["png", "jpg", "jpeg", "webp", "gif"]
        if (changeModel && imgURL.includes(urlExtension)) {
            const imageChatModel = localStorage.getItem('imageChatGptModel');
            if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
                const IChatModel = JSON.parse(imageChatModel);
                setChatModel(IChatModel);
                setChangeModel?.(false);
            }
        }
        if (imgURL.includes(urlExtension) && (gptModel?.name === "GPT-3.5-16K" || gptModel?.name === "GPT-4o mini")) {
            const imageChatModel = localStorage.getItem('imageChatGptModel');
            let selectedModel = imageChatModel ? JSON.parse(imageChatModel) : chatModels[1];
            if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
            }
            else {
                localStorage.setItem('imageChatGptModel', JSON.stringify(chatModels[1]));
                localStorage.setItem('GptModel', JSON.stringify(chatModels[1]));
            }
            setChatModel(selectedModel);
        }
    }

    const getImageSize = async (url: string): Promise<number> => {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            const contentLength = response.headers.get('Content-Length');
            return contentLength ? parseInt(contentLength) : 0;
        } catch (error) {
            console.error('Error fetching image size:', error);
            return 0;
        }
    };

    useEffect(() => {
        if (newMessages[0]?.images && newMessages[0]?.images.length > 0)
            setAllowDoc(true);
        else if (newMessages[0]?.files && newMessages[0]?.files.length > 0)
            setImgallow(true);
        else {
            setImgallow(false);
            setAllowDoc(false);
        }
    }, [newMessages])

    useEffect(() => {
        setURL!('')
    }, [])

    const documentMaxCountReached = (): boolean => {
        const max_count = currentPlan?.attributes?.max_document_chats as number;
        return (
            (userDetail?.user.activeSubscription.document_chat_count as number) >=
            max_count
        );
    };

    const onSubmit = async (e: FormEvent<EventTarget | HTMLFormElement>) => {
        e.preventDefault();

        const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
        const imgURL = ["png", "jpg", "jpeg", "webp", "gif"];
        const docURL= [ "pdf", "txt", "ppt", "pptx", "docx", "csv", "xls", "xlsx", "eml", "srt",]
        if (!imgURL.includes(urlExtension)) {
            if (documentMaxCountReached()) {
                setMessageId!("documentChat.plan.max_count");
                setISUploadURL!(false);
                setURL!('');
                // setSelectedFile!(null);
                // setIsMainScreenOpen!(true);
                return;
            }
        }

        if (((allowDoc && chatHistory) || (messages[0]?.images && messages[0]?.images.length > 0 && chatId)) && docURL.includes(urlExtension)) {
            setURL!('');
            setErrorModal({
                // message: "documentChat.url.fileFormat",
                message: formatMessage({id: "upload.file.validation.docURL"}),
                show: true,
            });
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
        else if (((imgallow && chatHistory) || (messages[0]?.files && messages[0]?.files.length > 0 && chatId)) && imgURL.includes(urlExtension)) {
            setURL!('');
            setErrorModal({
                // message: "documentChat.url.fileFormat",
                message: formatMessage({id: "upload.file.validation.imgURL"}),
                show: true,
            });
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }          
        else if (await validateURL()) {
            setIsURLLoading(true);
            setUploadUrl!(true);
            const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
            const imgURL = ["png", "jpg", "jpeg", "webp", "gif"]

            if (imgURL.includes(urlExtension)) {
                onSendMessage!(message ? message : "", chatModel, false, url ? [url] : [], [])
            }
            else {
                onSendMessage!(message ? message : "", chatModel, false, [], url ? [url] : [])
            }
            setTimeout(() => {
                setURL!('')
                setISUploadURL!(false)
                resetMessage!()
                setUploadUrl!(false);
            }, 50)
        }
    };

    const validateURL = async (): Promise<boolean> => {
        if (validUrl.isHttpsUri(url ? url : '')) {
            const conditionalAllowedFileTypes = getConditionalAllowedFileTypes();
            const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
            const imgURL = ["png", "jpg", "jpeg", "webp", "gif"]
            if ((validateFileType({ url: url, types: conditionalAllowedFileTypes }))) {

                if (imgURL.includes(urlExtension)) {
                    setIsURLLoading(true);
                    const imageSize = await getImageSize(url ? url : '');
                    const allowedImgSize = gptModel?.name.includes("GPT-4") ? 15 : 5;
                    const maxSizeInBytes = gptModel?.name.includes("GPT-4") ? 15 * 1024 * 1024 : 5 * 1024 * 1024; // 5 MB

                    if (imageSize > maxSizeInBytes) {
                        setURL!('');
                        setErrorModal({
                            message: formatMessage(
                                { id: 'ImgChat.file.fileSizeImage' },
                                { size: allowedImgSize }
                            ),
                            show: true,
                        });
                        resetMessage!();
                        setIsURLLoading(false);
                        setISUploadURL!(false);
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            } else {
                setURL!('');
                setErrorModal({
                    message: "documentChat.url.fileFormat",
                    show: true,
                });
                resetMessage!();
                setISUploadURL!(false);
                return false;
            }
        } else {
            setURL!('');
            setErrorModal({ message: "documentChat.url.validation", show: true });
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
    };               

    return (
        <>
            <Modal size='md' onClose={() => {
                if (!isURLLoading) {
                    setISUploadURL!(false);
                    setURL!('');
                }
            }}>        
                <div className='py-[20px] px-[30px]'>
                    <div className="text-center text-[22px] leading-[20px] font-semibold py-7">
                        <FormattedMessage id="doc.upload.enter.url" />
                    </div>
                    <form data-testid='document-form' className={styles.form}
                        onSubmit={onSubmit}
                    >
                        <input
                            className={classNames(styles.input, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                            autoFocus
                            placeholder={formatMessage({
                                id: "doc.url.palaceholder",
                            })}
                            value={url}
                            onChange={getFileURL}
                            data-testid='document-upload-input'
                        />
                        <button
                            data-testid='document-submit-btn'
                            className={classNames({ [styles.isLoading]: isURLLoading })}
                            type="submit"
                        >
                            {isURLLoading ? (
                                <Spinner extraSmall isWhite={true} />
                            ) : (
                                <FormattedMessage id="documentChat.url.submitBtn" />
                            )}
                        </button>
                    </form>
                </div>
            </Modal>
        </>
    )
}